import React, { useEffect } from 'react';
import { useClient } from '../../client/client';
import { UserSession } from '../utils/UserSession';
import { verdade } from '../utils/verdade';
import { useRouter } from 'next/router';

export type TUseUser = ReturnType<typeof useUserData>;

export const UserContext = React.createContext<TUseUser>(null as any);

export const UserProvider: React.FC = (props) => {
  const userContext = useUserData();

  return (
    <UserContext.Provider value={userContext}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return React.useContext(UserContext);
};

const useUserData = () => {
  const router = useRouter();

  const userClient = useClient('User', {
    fragment: `
      _id
      CNPJ
      notifications {
        readIds
        unreadIds
      }
    `,
    fetchOnMount: !!UserSession.getToken()
  });

  const notificationsClient = useClient('NotificationFindMany', {
    fragment: `_id`,
    fetchOnMount: !!UserSession.getToken()
  });

  useEffect(() => {
    UserSession.listen((token?: string) => {
      if (token) {
        userClient.fetch();
        notificationsClient.fetch();
      }
    });
  }, []);

  React.useEffect(() => {
    const logged = !!userClient.result?._id;
    const route = router.route;

    const isOpenRoute = openRoutes.includes(route);

    if (!logged && !isOpenRoute) {
      router.push('/');
    }

    /*
    if (logged && openRoutes) {
      router.push('/presentation');
    }
    */
  }, [userClient.result?._id, router.route]);

  return React.useMemo(
    function () {
      return {
        logout() {
          UserSession.clear();
          window.location.href = '/';
        },
        _id: userClient.result?._id || '',
        CNPJ: userClient.result?.CNPJ || '',
        notifications: verdade(notificationsClient.result).map((el) => ({
          _id: el._id
        }))
      };
    },
    [userClient.result, notificationsClient.result]
  );
};

const openRoutes = ['/'];
