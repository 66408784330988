import { CacheHandler } from './CacheHandler';

export class UserSessionBase extends CacheHandler {
  getToken = () => {
    return this.getItem('token');
  };

  setToken = (token: string) => {
    return this.setItem('token', token);
  };
}

export const UserSession = new UserSessionBase({ storageKey: 'UserSession' });
