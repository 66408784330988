import { isBrowser } from './isBrowser';

const ServerStorage: CacheStorage = {
  getItem: () => null,

  removeItem: () => {},

  setItem: () => {}
};

export class CacheHandler {
  storage!: CacheStorage;
  config: Config;
  private listeners: any[] = [];

  constructor(config: Config) {
    if (!config || !config.storageKey) {
      throw new Error('invalid storageKey');
    }
    this.config = config;
    this.setStorage();
  }

  setStorage = () => {
    const { storage, memory } = this.config;

    if (isBrowser()) {
      if (memory) {
        this.storage = new BrowserMemoryStorage();
      } else {
        if (!storage) {
          this.storage = localStorage;
        } else {
          this.storage = storage;
        }
      }
    } else {
      this.storage = ServerStorage;
    }

    if (typeof this.storage === 'undefined') {
      throw new Error('storage is empty');
    }
  };

  setItem = (key: string, value: any) => {
    const cached = JSON.parse(
      this.storage.getItem(this.config.storageKey) || '{}'
    );
    cached[key] = value;
    this.storage.setItem(
      this.config.storageKey,
      minifyJSON(JSON.stringify(cached))
    );
    this.listeners.forEach((ln) => ln(key, value));
  };

  getItem = (key: string): any => {
    const cached = JSON.parse(
      this.storage.getItem(this.config.storageKey) || '{}'
    );
    return cached[key];
  };

  clear = () => {
    this.storage.removeItem(this.config.storageKey);
  };

  listen = (fn: Listener) => {
    this.listeners.push(fn);
  };

  unlisten = (fn: Listener) => {
    this.listeners = this.listeners.filter((l) => l !== fn);
  };

  emit = (key: string, value: any) => {
    this.listeners.forEach((ln) => ln(key, value));
  };

  values = () => {
    return JSON.parse(this.storage.getItem(this.config.storageKey) || '{}');
  };
}

export type Listener = (key?: string, value?: any) => any;

class BrowserMemoryStorage implements CacheStorage {
  memory: any;
  store: { [key: string]: any } = {};
  memoryIndex: string;

  constructor() {
    this.memoryIndex = randomKey();
    this.memory = window;
    this.memory[this.memoryIndex] = {};
    this.store = this.memory[this.memoryIndex];
  }

  getItem = (key: string) => {
    return this.memory[this.memoryIndex][key];
  };

  removeItem = (key: string) => {
    delete this.memory[this.memoryIndex][key];
  };

  setItem = (key: string, value: string) => {
    if (typeof key !== 'string') {
      throw new Error('invalid key');
    }

    if (typeof value !== 'string') {
      throw new Error('invalid value');
    }

    this.memory[this.memoryIndex][key] = value;
  };
}

function randomKey() {
  return `memoryCacheEntry__${Math.random() * 1000}${
    Math.random() * 1000
  }`.replace(/\./gim, '');
}

export const minifyJSON = (json: string) => {
  return json.replace(/\n|\\n/gim, ' ').replace(/  /gim, '');
};

export interface CacheStorage {
  getItem(key: string): string | null;

  removeItem(key: string): void;

  setItem(key: string, value: string): void;
}

export type Config = {
  storage?: CacheStorage;
  memory?: boolean;
  storageKey: string;
};
